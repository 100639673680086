import AppHeader from "app/components/shared/AppHeader";
import AppContent from 'app/components/shared/AppContent';
import AppFooter from "app/components/shared/AppFooter";

function App() {
  return (
    <>
      <AppHeader />

      <AppContent />

      <AppFooter />
    </>
  );
}

export default App;
